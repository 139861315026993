import { HttpException } from ".";

import { HttpStatus } from "types/api";

/**
 * Throw when a user is not logged in and must be logged in to
 *   access a resource. This should rarely be called by anything
 *   except for the withAuthentication middleware.
 */
export class UnauthorizedException extends HttpException {
  public constructor(error: Error | string, description = "Unauthorized") {
    super(
      HttpException.createBody(error, description, HttpStatus.UNAUTHORIZED),
      HttpStatus.UNAUTHORIZED
    );

    Object.setPrototypeOf(this, UnauthorizedException.prototype);
  }
}
