import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { THEME_OPTIONS } from "constants/themes";
import type { AppProps } from "next/app";
import "styles/globals.css";

import { Providers } from "components/Providers";

export default function App({ Component, pageProps }: AppProps) {
  /*
   * you can edit the theme by referencing
   * default theme object
   * here: https://mui.com/material-ui/customization/default-theme/
   */

  const theme = createTheme(THEME_OPTIONS);
  return (
    <Providers>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main>
          <Component {...pageProps} />
        </main>
      </ThemeProvider>
    </Providers>
  );
}
