import { HttpException } from ".";

import { HttpStatus } from "types/api";

/**
 * Throw when a request type mapping did not match. For example
 *   if a POST request is expected and a DELETE is sent
 */
export class MethodNotAllowedException extends HttpException {
  public constructor(
    error: Error | string,
    description = "Method not allowed"
  ) {
    super(
      HttpException.createBody(
        error,
        description,
        HttpStatus.METHOD_NOT_ALLOWED
      ),
      HttpStatus.METHOD_NOT_ALLOWED
    );

    Object.setPrototypeOf(this, MethodNotAllowedException.prototype);
  }
}
