import { HttpException } from ".";

import { HttpStatus } from "types/api";

/**
 * Throw when a user tries signing up with an email that already exists
 */
export class UnprocessableEntityException extends HttpException {
  public constructor(
    error: Error | string,
    description = "Unprocessable Entity"
  ) {
    super(
      HttpException.createBody(
        error,
        description,
        HttpStatus.UNPROCESSABLE_ENTITY
      ),
      HttpStatus.UNPROCESSABLE_ENTITY
    );

    Object.setPrototypeOf(this, UnprocessableEntityException.prototype);
  }
}
