import { axiosClient } from "clients/axios";

import {
  ChangePasswordForceParams,
  ChangePasswordParams,
  ForgotPasswordParams,
  RegisterParams,
  SignInParams,
  SignInResponse,
} from "types/api/user";
import { ApiPostSuccess } from "types/base";

export const login = async (body: SignInParams) => {
  const response = await axiosClient.post<SignInResponse>("auth/login", body);

  return response.data;
};

export const register = async (body: RegisterParams) => {
  const response = await axiosClient.post<ApiPostSuccess>(
    "auth/register",
    body
  );
  return response.data;
};

export const autoLogin = async () => {
  const response = await axiosClient.post<SignInResponse>("auth/autoLogin", {});

  return response.data;
};

export const logout = async () => {
  const response = await axiosClient.post("auth/logout", {});

  return response;
};

export const forgotPassword = async (body: ForgotPasswordParams) => {
  const response = await axiosClient.post("auth/forgotPassword", body);

  return response;
};

export const resetAccount = async (body: ForgotPasswordParams) => {
  const response = await axiosClient.post("auth/resetAccount", body);

  return response;
};

export const changePassword = async (body: ChangePasswordParams) => {
  const response = await axiosClient.post("auth/changePassword", body);

  return response;
};

export const changePasswordForce = async (body: ChangePasswordForceParams) => {
  const response = await axiosClient.post("auth/changePasswordForce", body);

  return response;
};

export const changePasswordFromSettings = async (
  body: ChangePasswordForceParams
) => {
  const response = await axiosClient.post("auth/changePasswordSettings", body);

  return response;
};
