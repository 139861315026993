import React, { createContext, FC, useReducer } from "react";

import { RouteActions } from "./nav-context-actions";

import { Props } from "types/base";
import { Client } from "types/api";

interface State {
  company: Client | null;
  year: number | null;
  companyList: Client[] | null;
}

interface AuthContextState extends State {
  changeCompany: (body: Client) => Promise<void>;
  changeYear: (body: number) => Promise<void>;
  collectCompanies: (body: Client[]) => Promise<void>;
}

const initialState: State = {
  company: null,
  year: null,
  companyList: null,
};

const reducer = (state: State, action: RouteActions): State => {
  switch (action.type) {
    case "COMPANY_CHANGE": {
      return { ...state, company: action.payload.company };
    }

    case "YEAR_CHANGE": {
      return { ...state, year: action.payload.year };
    }

    case "CAPTURE_ALL_COMPANIES": {
      return { ...state, companyList: action.payload.companies };
    }

    default:
      return { ...state };
  }
};

const NavContext = createContext<AuthContextState>({
  ...initialState,
  changeCompany: async () => Promise.resolve(undefined),
  changeYear: async () => Promise.resolve(undefined),
  collectCompanies: async () => Promise.resolve(undefined),
});

export const NavProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeCompany = async (body: Client) => {
    window.localStorage.setItem("code", body.code);

    dispatch({
      type: "COMPANY_CHANGE",
      payload: { company: body },
    });
  };

  const changeYear = async (body: number) => {
    window.localStorage.setItem("year", body.toString());

    dispatch({
      type: "YEAR_CHANGE",
      payload: { year: body },
    });
  };

  const collectCompanies = async (body: Client[]) => {
    dispatch({
      type: "CAPTURE_ALL_COMPANIES",
      payload: { companies: body },
    });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      changeCompany,
      changeYear,
      collectCompanies,
    }),
    [state]
  );

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export default NavContext;
