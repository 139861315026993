import { HttpException } from ".";

import { HttpStatus } from "types/api";

/**
 * Throw when an input provided by the user is invalid such as
 *   body validation or query validation.
 */
export class BadRequestException extends HttpException {
  public constructor(error: Error | string, description = "Bad Request") {
    super(
      HttpException.createBody(error, description, HttpStatus.BAD_REQUEST),
      HttpStatus.BAD_REQUEST
    );

    Object.setPrototypeOf(this, BadRequestException.prototype);
  }
}
