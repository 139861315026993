import { fetcher } from "clients/axios";
import { AuthProvider } from "contexts/auth-context";
import { NavProvider } from "contexts/nav-context";
import { SnackbarProvider } from "notistack";
import React from "react";
import { SWRConfig } from "swr";

import { Props } from "types/base";

export const Providers: React.FC<Props> = ({ children }) => (
  <SnackbarProvider
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    dense
    maxSnack={3}
  >
    <AuthProvider>
      <NavProvider>
        <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
      </NavProvider>
    </AuthProvider>
  </SnackbarProvider>
);
