import React, { createContext, FC, useReducer } from "react";
import * as AuthRequests from "requests/auth";

import { AuthContextAction } from "./auth-context-actions";

import { SignInParams, RegisterParams, UserWithRoles } from "types/api/user";
import { Client } from "types/api";
import { ApiPostSuccess, Props } from "types/base";

interface State {
  user: UserWithRoles | null | undefined;
  company: Client | null;
  isAuthenticated: boolean;
}

interface AuthContextState extends State {
  login: (
    body: SignInParams
  ) => Promise<{ valid: string; user: UserWithRoles | undefined } | undefined>;
  autoLogin: () => Promise<string | undefined>;
  register: (body: RegisterParams) => Promise<ApiPostSuccess | undefined>;
  logout: () => Promise<void>;
}

const initialState: State = {
  isAuthenticated: false,
  user: null,
  company: null,
};

const reducer = (state: State, action: AuthContextAction): State => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case "REGISTER":
      return { ...state };

    case "COMPANY":
      return { ...state, company: action.payload.company };

    default:
      return { ...state };
  }
};

const AuthContext = createContext<AuthContextState>({
  ...initialState,
  login: async () => Promise.resolve(undefined),
  register: async () => Promise.resolve(undefined),
  autoLogin: async () => Promise.resolve(undefined),
  logout: async () => Promise.resolve(undefined),
});

export const AuthProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (
    body: SignInParams
  ): Promise<{ valid: string; user: UserWithRoles | undefined }> => {
    const { valid, status, user } = await AuthRequests.login(body);

    if (user)
      dispatch({
        type: "LOGIN",
        payload: { user, isAuthenticated: true },
      });

    return { valid: valid ? (status as string) : "", user };
  };

  const autoLogin = async (): Promise<string> => {
    const { valid, status, user } = await AuthRequests.autoLogin();

    if (user)
      dispatch({
        type: "LOGIN",
        payload: { user, isAuthenticated: true },
      });

    return valid ? (status as string) : "";
  };

  const register = async (body: RegisterParams) => {
    const response = await AuthRequests.register(body);
    dispatch({
      type: "REGISTER",
    });
    return response;
  };

  const logout = async () => {
    dispatch({
      type: "COMPANY",
      payload: { isAuthenticated: false, user: null, company: null },
    });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      login,
      register,
      autoLogin,
      logout,
    }),
    [state]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
