import {
  AcumaticaValueBool,
  AcumaticaValueBoolean,
  AcumaticaValueNumber,
  AcumaticaValueString,
} from "./acumatica-common";

export type SalesOrderOverview = {
  id: string;
  order_type?: string;
  order_number?: number;
  customer?: string;
  status?: string;
  date?: string;
  date_requested?: string;
  location?: string;
  contact?: string;
  order_total?: number;
  currency?: string;
  order_quantity?: number;
  vat_exampt_total?: number;
  vat_taxable_total?: number;
  tax_total?: number;
  customer_order_number?: string;
  note?: number | string;
  description?: string;
  items_count?: number;
  address_line1: string;
  address_line2: string;
  tracking_number: string;
  shipment_number: string;
  carrier: string;
  city: string;
  country: string;
  postal_code: string;
  state: string;
};

export type SalesOrder = {
  id: string;
  customer_order_number: string;
  customer: SalesOrderCustomer;
  ship_to_id?: string;
  order_total: number | string;
  order_quantity: number | string;
  shipped_quantity: number | string;
  carrier: string;
  note: number | string;
  tracking_no: string;
  status: string;
  effective_date: string;
};

export type SalesOrderCustomer = {
  title: string;
  sub: string;
};
// Open, Pending Processing, Shipping, Back Order, or Completed
//     * there is also 'Canceled

export type SalesOrderStatusChartData = {
  total: number;
  open: number;
  pendingProcessing: number;
  shipping: number;
  backOrder: number;
  completed: number;
  canceled: number;
  others: number;
};

export type SalesOrderItem = {
  id: string;
  branch?: string;
  description?: string;
  inventory_id?: string;
  warehouse?: string;
  uom?: string;
  quantity?: number;
  open_quantity?: number;
  unit_price?: number;
  discount?: number;
};

export type TotalSalesData = {
  yearly: TotalSalesYearlyData;
  monthly: TotalSalesMonthlyData;
};

export type SalesReportCustomerData = Record<string, TotalSalesYearlyData>;
/*
 *
 * 'customerId': {
 *  2022: {
 *   orderQty: 12,
 *   orderAmount: 34
 *  },
 *  2021: {
 *   orderQty: 1,
 *   orderAmount: 2
 *  },
 * }
 *  */

export type CustomerSalesData = Record<string, TotalSalesData>;

export type TotalSalesYearlyData = Record<number, TotalSalesEntry>;

export type TotalSalesMonthlyData = Record<
  number,
  Record<number, TotalSalesEntry>
>;

export type TotalSalesEntry = {
  orderQty: number;
  orderAmount: number;
};
export type SalesOrderShipment = {
  id: string;
  shipment_type?: string;
  document_nbr?: string;
  status?: string;
  shipment_date?: string;
  shipped_qty?: number;
  shipped_weight?: number;
  shipped_volume?: number;
  invoice_type?: string;
  invoice_nbr?: string;
  inventory_doc_type?: string;
  invoice_ref_nbr?: string;
  lot?: string;
  expiry_date?: string;
};

export type AcumaticaSalesOrder = {
  id: string;
  rowNumber?: number;
  note: AcumaticaValueString;
  Approved?: AcumaticaValueBool;
  BaseCurrencyID?: AcumaticaValueString;
  BillToAddressOverride?: AcumaticaValueBool;
  BillToContactOverride?: AcumaticaValueBool;
  CashAccount?: unknown;
  Carrier?: AcumaticaValueString;
  Company?: AcumaticaValueString;
  ControlTotal?: AcumaticaValueNumber;
  CreditHold?: AcumaticaValueBool;
  CurrencyID?: AcumaticaValueString;
  CurrencyRate?: AcumaticaValueNumber;
  CurrencyRateTypeID?: AcumaticaValueString;
  CustomerID?: AcumaticaValueString;
  CustomerOrder?: AcumaticaValueString;
  Date?: AcumaticaValueString;
  Description?: AcumaticaValueString;
  DestinationWarehouseID?: AcumaticaValueString;
  DisableAutomaticDiscountUpdate?: AcumaticaValueBool;
  EffectiveDate: AcumaticaValueString;
  ExternalRef?: unknown;
  Hold?: AcumaticaValueBool;
  IsTaxValid?: AcumaticaValueBool;
  LastModified?: AcumaticaValueString;
  LocationID?: AcumaticaValueString;
  NewCard?: AcumaticaValueBool;
  OrderedQty?: AcumaticaValueNumber;
  ShippedQty?: AcumaticaValueNumber;
  OrderNbr: AcumaticaValueNumber;
  OrderTotal?: AcumaticaValueNumber;
  OrderType?: AcumaticaValueString;
  PaymentCardIdentifier?: unknown;
  PaymentMethod?: AcumaticaValueString;
  PaymentProfileID?: unknown;
  PaymentRef?: unknown;
  PreAuthorizationNbr?: unknown;
  PreAuthorizedAmount?: AcumaticaValueNumber;
  PreferredWarehouseID?: unknown;
  ReciprocalRate?: AcumaticaValueNumber;
  RequestedOn?: AcumaticaValueString;
  ShipToAddressOverride?: AcumaticaValueBool;
  ShipToContactOverride?: AcumaticaValueBool;
  ShipVia?: unknown;
  Status?: AcumaticaValueString;
  TaxTotal?: AcumaticaValueNumber;
  TrackingNumber?: AcumaticaValueString;
  VATExemptTotal?: AcumaticaValueNumber;
  VATTaxableTotal?: AcumaticaValueNumber;
  custom?: unknown;
};

export type AcumaticaSalesOrderAllocation = {
  id: string;
  rowNumber?: number;
  note?: string;
  Allocated?: AcumaticaValueBool;
  AllocWarehouseID?: AcumaticaValueString;
  Completed?: AcumaticaValueBool;
  CustomerOrderNbr?: AcumaticaValueString;
  ExpirationDate?: AcumaticaValueString;
  InventoryID?: AcumaticaValueString;
  LineNbr?: AcumaticaValueNumber;
  LocationID?: AcumaticaValueString;
  LotSerialNbr?: AcumaticaValueString;
  OrderNbr?: AcumaticaValueString;
  OrderType?: AcumaticaValueString;
  Qty?: AcumaticaValueNumber;
  QtyOnShipments?: AcumaticaValueNumber;
  QtyReceived?: AcumaticaValueNumber;
  RelatedDocument?: AcumaticaValueString;
  SchedOrderDate?: AcumaticaValueString;
  ShipOn?: AcumaticaValueString;
  SplitLineNbr?: AcumaticaValueNumber;
  UOM?: AcumaticaValueString;
  custom?: unknown;
};

export type AcumaticaSalesOrderItem = {
  id: string;
  rowNumber?: number;
  note?: string;
  Account?: AcumaticaValueString;
  AlternateID?: AcumaticaValueString;
  AutoCreateIssue?: AcumaticaValueBool;
  AverageCost?: unknown;
  Branch?: AcumaticaValueString;
  CalculateDiscountsOnImport?: AcumaticaValueNumber;
  Completed?: AcumaticaValueBool;
  DiscountAmount?: AcumaticaValueNumber;
  DiscountCode?: unknown;
  DiscountedUnitPrice?: AcumaticaValueNumber;
  DiscountPercent?: AcumaticaValueNumber;
  ExtendedPrice?: AcumaticaValueNumber;
  FreeItem?: AcumaticaValueBool;
  InventoryID?: AcumaticaValueString;
  ItemClass?: AcumaticaValueString;
  LastModifiedDate?: AcumaticaValueString;
  LineDescription?: AcumaticaValueString;
  LineNbr?: AcumaticaValueNumber;
  LineType?: AcumaticaValueString;
  Location?: AcumaticaValueString;
  ManualDiscount?: AcumaticaValueBool;
  MarkForPO?: AcumaticaValueBool;
  OpenQty?: AcumaticaValueNumber;
  Operation?: AcumaticaValueString;
  OrderQty?: AcumaticaValueNumber;
  OrderType?: AcumaticaValueString;
  OrderNbr?: AcumaticaValueNumber;
  OvershipThreshold?: AcumaticaValueNumber;
  POSource?: unknown;
  QtyOnShipments?: AcumaticaValueNumber;
  ReasonCode?: unknown;
  RequestedOn?: AcumaticaValueString;
  SalespersonID?: AcumaticaValueString;
  ShipOn?: AcumaticaValueString;
  ShippingRule?: AcumaticaValueString;
  TaxCategory?: AcumaticaValueString;
  UnbilledAmount?: AcumaticaValueNumber;
  UndershipThreshold?: AcumaticaValueNumber;
  UnitCost?: AcumaticaValueNumber;
  UnitPrice?: AcumaticaValueNumber;
  UOM?: AcumaticaValueString;
  WarehouseID?: AcumaticaValueString;
  custom?: unknown;
  files?: unknown[];
  Allocations?: AcumaticaSalesOrderAllocation[];
};

export type AcumaticaPurchaseOrderItem = {
  id: string;
  rowNumber?: number;
  note?: string;
  Branch: AcumaticaValueString;
  ControlTotal: AcumaticaValueNumber;
  CurrencyID: AcumaticaValueString;
  Date: AcumaticaValueString;
  DefaultPrice: AcumaticaValueNumber;
  Description: AcumaticaValueString;
  Hold: AcumaticaValueBool;
  LastModifiedDateTime: AcumaticaValueString;
  LineTotal: AcumaticaValueNumber;
  Location: AcumaticaValueString;
  OrderNbr: AcumaticaValueString;
  OrderTotal: AcumaticaValueNumber;
  PromisedOn: AcumaticaValueString;
  Status: AcumaticaValueString;
  TaxTotal: AcumaticaValueNumber;
  Terms: AcumaticaValueString;
  Type: AcumaticaValueString;
  VendorID: AcumaticaValueString;
  Details: PurchaseOrderDetail[];
  ShippingInstructions: PurchaseOrderShippingInstructions;
};

export type PurchaseOrderShippingInstructions = {
  id: string;
  rowNumber: number;
  note: AcumaticaValueString;
  ShippingDestinationType: AcumaticaValueString;
  ShippingLocation: AcumaticaValueString;
  ShippingSalesOrder: AcumaticaValueString;
  ShipTo: AcumaticaValueString;
  ShipToAddressOverride: AcumaticaValueBoolean;
  ShipToContactOverride: AcumaticaValueBoolean;
  Warehouse: AcumaticaValueString;
};

export type PurchaseOrderDetail = {
  id: string;
  rowNumber?: number;
  note?: string;
  Account: AcumaticaValueString;
  AlternateID: AcumaticaValueString;
  BranchID: AcumaticaValueString;
  CalculateDiscountsOnImport: AcumaticaValueString;
  Cancelled: AcumaticaValueBool;
  Completed: AcumaticaValueBool;
  CompleteOn: AcumaticaValueNumber;
  Description: AcumaticaValueString;
  ExtendedCost: AcumaticaValueNumber;
  InventoryID: AcumaticaValueString;
  LineDescription: AcumaticaValueString;
  LineNbr: AcumaticaValueNumber;
  LineType: AcumaticaValueString;
  MaxReceiptPercent: AcumaticaValueNumber;
  MinReceiptPercent: AcumaticaValueNumber;
  OrderNbr: AcumaticaValueString;
  OrderQty: AcumaticaValueNumber;
  OrderType: AcumaticaValueString;
  OrigPONbr: AcumaticaValueString;
  OrigPOType: AcumaticaValueString;
  Promised: AcumaticaValueString;
  QtyOnReceipts: AcumaticaValueNumber;
  ReceiptAction: AcumaticaValueString;
  ReceivedAmount: AcumaticaValueString;
  Requested: AcumaticaValueString;
  TaxCategory: AcumaticaValueString;
  UnitCost: AcumaticaValueNumber;
  UOM: AcumaticaValueString;
  WarehouseID: AcumaticaValueString;
};
export interface AcumaticaSalesOrderWithDetails extends AcumaticaSalesOrder {
  Details: AcumaticaSalesOrderItem[];
  ShipToAddress: AcumaticaShipToAddress;
}

export type AcumaticaShipToAddress = {
  AddressLine1: AcumaticaValueString;
  AddressLine2: AcumaticaValueString;
  City: AcumaticaValueString;
  Country: AcumaticaValueString;
  PostalCode: AcumaticaValueString;
  State: AcumaticaValueString;
};

export type AcumaticaShipmentDetails = {
  LotSerialNbr: AcumaticaValueString;
  ExpirationDate: AcumaticaValueString;
};

export type AcumaticaPackage = {
  TrackingNbr: AcumaticaValueString;
  Description: AcumaticaValueString;
};

export type AcumaticaPackageDetails = {
  Packages: AcumaticaPackage[];
  ShipmentNbr: AcumaticaValueString;
};

export type AcumaticaSalesOrderShipment = {
  id: string;
  rowNumber?: number;
  note?: string | null;
  InventoryDocType?: AcumaticaValueString;
  InventoryRefNbr?: AcumaticaValueString;
  InvoiceNbr?: AcumaticaValueString;
  InvoiceType?: AcumaticaValueString;
  ShipmentDate?: AcumaticaValueString;
  ShipmentNbr?: AcumaticaValueString;
  ShipmentType?: AcumaticaValueString;
  ExpiryDate?: AcumaticaValueString;
  Lot?: AcumaticaValueString;
  ShippedQty?: AcumaticaValueNumber;
  ShippedVolume?: AcumaticaValueNumber;
  ShippedWeight?: AcumaticaValueNumber;
  Status?: AcumaticaValueString;
  custom?: unknown;
  files?: unknown[];
};

export interface AcumaticaSalesOrderWithShipments extends AcumaticaSalesOrder {
  Shipments: AcumaticaSalesOrderShipment[];
}

export enum TotalSalesDisplayUnit {
  dollar = "Dollars",
  unit = "Units",
}

export enum SalesOrderAutomationStatuses {
  order_received = "order_received",
  sales_order_created = "sales_order_created",
  shipment_open = "shipment_open",
  shipment_confirmed = "shipment_confirmed",
  shipment_invoiced = "shipment_invoiced",
  shipment_completed = "shipment_completed",
}

export type SalesOrderAutomationLogs = {
  log_id: string;
  customer: string;
  order_json: string;
  status: SalesOrderAutomationStatuses;
  acumatica_sales_order_id: string | undefined;
  acumatica_shipment_id: string | undefined;
  beacon_order_id: string | undefined;
  order_received_timestamp: Date | undefined;
  sales_order_created_timestamp: Date | undefined;
  shipment_open_timestamp: Date | undefined;
  shipment_confirmed_timestamp: Date | undefined;
  shipment_invoiced_timestamp: Date | undefined;
  shipment_completed_timestamp: Date | undefined;
  created_at: Date;
  updated_at: Date;
};
export type SalesOrderAutomationLogsCreateParams = Partial<
  Omit<SalesOrderAutomationLogs, "created_at" | "log_id" | "updated_at">
>;
