import { HttpException } from ".";

import { HttpStatus } from "types/api";

/**
 * Throw when a record already exists. For example, on a primary
 *   key conflict during a POST request.
 */
export class ConflictException extends HttpException {
  public constructor(error: Error | string, description = "Conflict") {
    super(
      HttpException.createBody(error, description, HttpStatus.CONFLICT),
      HttpStatus.CONFLICT
    );

    Object.setPrototypeOf(this, ConflictException.prototype);
  }
}
