import * as Sentry from "@sentry/nextjs";
import { AxiosError } from "axios";
import { NextApiResponse } from "next";

import {
  BadRequestException,
  NotFoundException,
  ConflictException,
  ForbiddenException,
  MethodNotAllowedException,
  UnauthorizedException,
  UnprocessableEntityException,
  HttpException,
} from "../exceptions";

import { HttpStatus, ApiError } from "types/api";

export const errorHandler = async (error: AxiosError) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  if (!error.response) {
    throw new Error(error.message);
  }
  const err = error.response.data as ApiError;
  if (err.statusCode && err.statusCode === HttpStatus.UNAUTHORIZED) {
    throw new UnauthorizedException(err.message);
  } else if (err.statusCode && err.statusCode === HttpStatus.NOT_FOUND) {
    throw new NotFoundException(err.message);
  } else if (err.statusCode && err.statusCode === HttpStatus.BAD_REQUEST) {
    throw new BadRequestException(err.message);
  } else if (err.statusCode && err.statusCode === HttpStatus.FORBIDDEN) {
    throw new ForbiddenException(err.message);
  } else if (
    err.statusCode &&
    err.statusCode === HttpStatus.METHOD_NOT_ALLOWED
  ) {
    throw new MethodNotAllowedException(err.message);
  } else if (err.statusCode && err.statusCode === HttpStatus.CONFLICT) {
    throw new ConflictException(err.message);
  } else if (
    err.statusCode &&
    err.statusCode === HttpStatus.UNPROCESSABLE_ENTITY
  ) {
    throw new UnprocessableEntityException(err.message);
  } else {
    throw new Error(err.message);
  }
};

export const errorHandlerUnknown = async (
  error: unknown,
  res: NextApiResponse<ApiError>
) => {
  if (error instanceof HttpException) {
    const status = error.getStatus();
    const body = error.getResponse();
    res.status(status).json(body);
  } else {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    const err = error as Error;
    // Capture manually
    Sentry.captureException(error);
    await Sentry.flush(2000);

    res.status(500).json({
      message: err.message,
      error: "Internal Error",
      statusCode: 500,
    });
  }
};
