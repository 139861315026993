import { ThemeOptions } from "@mui/material";

export const BASE_PX = 16;
export const pxToEm = (n: number) => `${n / BASE_PX}em`;

export const NORRIZON_BLUE = "#0060AF";
export const NORRIZON_BLACK = "#111827";

export const CHART_PURPLE = "#6E7AD8";
export const CHART_GREY = "#69707C";
export const CHART_BLUE = "#0C7CD5";
export const CHART_GREEN = "#7BC67E";

export const INFO_COLOR = "#64B6F7";
export const INFO_COLOR_BG_GRADIENT =
  "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #64B6F7";
export const SUCCESS_COLOR = "#14B8A6";

export const WARNING_COLOR = "#FFB020";
export const WARNING_COLOR_BG_GRADIENT =
  "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FFB020";

export const ERROR_COLOR = "#D14343";

export const PAGE_BACKGROUND_WHITE = "#F9FAFC";
export const CARD_BACKGROUND_WHITE = "#FFF";
export const TABLE_HEAD_BACKGROUND_GREY = "#F3F4F6";

export const DIVIDER_GREY = "#E6E8F0";
export const TEXT_GREY = "#6B7280";
export const THEME_OPTIONS: ThemeOptions = {
  shape: {
    borderRadius: 8,
  },
  palette: {
    mode: "light",
    primary: {
      main: NORRIZON_BLUE,
    },
    error: { main: ERROR_COLOR },
    warning: { main: WARNING_COLOR },
    info: { main: INFO_COLOR },
    success: { main: SUCCESS_COLOR },
    divider: DIVIDER_GREY,
    background: {
      paper: CARD_BACKGROUND_WHITE,
      default: PAGE_BACKGROUND_WHITE,
    },
  },

  typography: {
    htmlFontSize: BASE_PX,
    fontSize: 14,
    fontFamily: "",
    h1: {
      fontSize: pxToEm(32),
      lineHeight: pxToEm(44),
      fontWeight: 600,
      color: NORRIZON_BLACK,
    },
    h2: {
      fontSize: pxToEm(24),
      lineHeight: pxToEm(33),
      fontWeight: 600,
      color: NORRIZON_BLACK,
    },
    h3: {
      fontSize: pxToEm(18),
      lineHeight: pxToEm(24.75),
      fontWeight: 600,
      color: NORRIZON_BLACK,
    },
    h4: {
      fontSize: pxToEm(14),
      lineHeight: pxToEm(22),
      fontWeight: 300,
      color: TEXT_GREY,
    },
    h5: {
      fontSize: pxToEm(14),
      lineHeight: pxToEm(22),
      fontWeight: 500,
      color: NORRIZON_BLACK,
    },
    h6: {
      fontSize: pxToEm(12),
      lineHeight: pxToEm(30),
      fontWeight: 300,
      textTransform: "uppercase",
      letterSpacing: 0.5,
      color: NORRIZON_BLACK,
    },
    body1: {
      color: NORRIZON_BLACK,
      fontSize: pxToEm(14),
      lineHeight: pxToEm(18),
    },
    body2: {
      color: NORRIZON_BLUE,
      fontSize: pxToEm(14),
      lineHeight: pxToEm(18),
    },
    subtitle1: { color: TEXT_GREY, fontSize: pxToEm(14) },
    subtitle2: {
      color: TEXT_GREY,
      fontSize: pxToEm(9),
      lineHeight: pxToEm(11),
    },
  },
};
