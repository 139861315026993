import { HttpException } from ".";

import { HttpStatus } from "types/api";

/**
 * Throw when a user accesses an endpoint that they do not have
 *   the proper permissions for. For example, if a user tries
 *   to edit an user, and they do not have the edit_user
 *   permission. Or if a users session has timed out.
 *
 *   Generally  only used by the useAuthorization hook.
 */
export class ForbiddenException extends HttpException {
  public constructor(error: Error | string, description = "Forbidden") {
    super(
      HttpException.createBody(error, description, HttpStatus.FORBIDDEN),
      HttpStatus.FORBIDDEN
    );

    Object.setPrototypeOf(this, ForbiddenException.prototype);
  }
}
