import { HttpException } from ".";

import { HttpStatus } from "types/api";

/**
 * Throw when a single record is looked up but not found, or
 *   a user does not have permission to access it. Throwing
 *   unauthorized in these cases _can_ be a security risk.
 */
export class NotFoundException extends HttpException {
  public constructor(error: Error | string, description = "Not Found") {
    super(
      HttpException.createBody(error, description, HttpStatus.NOT_FOUND),
      HttpStatus.NOT_FOUND
    );

    Object.setPrototypeOf(this, NotFoundException.prototype);
  }
}
