import { ApiError } from "types/api/error";

/**
 * Base api error type
 * Borrowed from: https://github.com/nestjs/nest/blob/master/packages/common/exceptions/http.exception.ts
 */
export class HttpException extends Error {
  private readonly response: ApiError;

  private readonly status: number;

  public constructor(response: ApiError, status: number) {
    super();
    this.response = response;
    this.status = status;
    this.message = this.response.message;
    this.name = this.constructor.name;
  }

  public static createBody(
    error: Error | string,
    description: string,
    statusCode: number
  ): ApiError {
    if (typeof error === "string") {
      return { statusCode, message: error, error: description };
    }

    return { statusCode, message: error.message, error: description };
  }

  public getResponse(): ApiError {
    return this.response;
  }

  public getStatus(): number {
    return this.status;
  }
}
