import axios, { AxiosError } from "axios";

import { errorHandler } from "./error-handler";

import { ApiError } from "types/api/error";

// The main axios client
export const axiosClient = axios.create({
  baseURL: `/api/`,
});

// Fetcher for user with useSWR
export const fetcher = async <T>(url: string) => {
  try {
    const response = await axiosClient.get<T>(url, {
      withCredentials: true,
    });
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error as AxiosError);
  }
};

// Refresh the users token if expired
axiosClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<ApiError>) => {
    const originalRequest = error.config;

    // Only refresh if we need to
    if (
      error.response?.status === 401 &&
      error.response.data.message === "Token expired"
    ) {
      // DO REFRESH TOKEN HERE
      return axios.post("/api/auth/refresh").then((res) => {
        if (res.status === 200 && originalRequest) {
          return axios(originalRequest);
        }
        window.location.href = "/?error=token_expired";
        return null;
      });
    }
    if (error.response?.data.message === "Invalid JWT") {
      window.location.href = "/?error=token_expired";
    }
    return errorHandler(error);
  }
);
